<template>
  <v-autocomplete
    v-model="iInvoiceId"
    v-bind="$attrs"
    :items="items"
    :search-input.sync="sSearch"
    :loading="loading"
    :label="$t('invoice')"
    hide-details="auto"
    item-value="id"
    item-text="order_number"
    outlined
    dense
    no-filter
    @change="onChange"
  >
    <template #selection="{ item }">
      <invoice-preview :value="item" />
    </template>

    <template #item="{ item }">
      <invoice-preview :value="item" />
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import type { DebounceFunction } from "@/plugins/helpers";
import { Invoice, InvoiceCollection } from "@planetadeleste/vue-mc-gw";
import InvoicePreview from "@/modules/invoices/components/InvoicePreview.vue";
import { assign, castArray, debounce, get, isEmpty, isNil } from "lodash";

@Component({
  components: { InvoicePreview },
})
export default class InvoiceSelect extends Vue {
  @VModel({ type: Number }) iInvoiceId!: number;
  @Prop({ type: Object, default: () => ({}) }) readonly filters!: Record<
    string,
    any
  >;
  @Prop([Number, Array]) readonly omit!: number | number[];

  obCollection: InvoiceCollection = new InvoiceCollection();
  fnDebounceSearch!: DebounceFunction;
  sSearch = "";

  @Watch("sSearch")
  onSearch() {
    this.fnDebounceSearch();
  }

  mounted() {
    this.$nextTick(this.preLoad);
  }

  created() {
    this.fnDebounceSearch = debounce(this.search, 500);
  }

  async preLoad() {
    this.sSearch = "";
    this.$emit("input", undefined);

    if (this.obCollection.length) {
      this.obCollection.clear();
    }

    this.obCollection.clearFilters();

    if (!isEmpty(this.filters)) {
      this.obCollection.filterBy(this.filters);
    }

    await this.obCollection.limit(10).list();
    this.load();
  }

  async load() {
    if (!this.iInvoiceId) {
      return;
    }

    if (this.obCollection.length) {
      const obInvoice = this.obCollection.find({ id: this.iInvoiceId });
      if (obInvoice) {
        return;
      }
    }

    const obSelectedInvoice = new Invoice(
      { id: this.iInvoiceId },
      this.obCollection
    );
    await obSelectedInvoice.fetch();
    this.obCollection.add(obSelectedInvoice);

    this.onChange(this.iInvoiceId);
  }

  async search() {
    if (this.loading || isNil(this.sSearch)) {
      return;
    }

    if (!isNil(this.sSearch) && this.sSearch.length) {
      const obFilters = assign(
        this.filters,
        this.obCollection.get("filters", {})
      );

      if (get(obFilters, "search") == this.sSearch) {
        return;
      }

      obFilters.search = this.sSearch;
      this.obCollection.filterBy(obFilters);
    }

    this.obCollection.clear();
    this.obCollection.page(1);

    await this.obCollection.list();
  }

  onChange(iValue: number) {
    const obModel = this.obCollection.find({ id: iValue });
    this.$emit("change", obModel);
  }

  get loading() {
    return this.obCollection.loading;
  }

  get items() {
    const arOmitIdList = this.omit ? castArray(this.omit) : null;
    const obCollection: InvoiceCollection = arOmitIdList
      ? this.obCollection.filter(
          (obModel: Invoice) => !arOmitIdList.includes(obModel.id)
        )
      : this.obCollection;

    return obCollection.getModelList();
  }

  get selected() {
    return this.iInvoiceId && this.obCollection.length
      ? this.obCollection.find({ id: this.iInvoiceId })
      : undefined;
  }
}
</script>
