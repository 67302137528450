<template>
  <tr :class="rowClass">
    <table-cell :label="$t('invoice')">
      <div class="d-flex align-center">
        <v-btn
          v-if="canAdd && !signed && !inView"
          :disabled="!validRow"
          class="mr-4"
          color="success"
          icon
          outlined
          x-small
          @click="onAddItem"
        >
          <icon-add />
        </v-btn>

        <v-list-item v-if="obItemInvoice.id">
          <v-list-item-title>
            <span v-if="sCfeName" v-text="sCfeName" />
            <span
              class="ml-2 font-weight-bold"
              v-text="obItemInvoice.order_serial"
            />
            <span class="ml-2" v-text="obItemInvoice.order_number" />
          </v-list-item-title>
        </v-list-item>
        <invoice-select
          v-else-if="bFiltersDone"
          :filters="obInvoiceFilters"
          :omit="omit"
          :value="itemInvoiceId"
          @change="onSelectItemInvoice"
        />
        <v-select
          v-else-if="sError"
          :hint="sError"
          dense
          disabled
          outlined
          persistent-hint
        />
      </div>
    </table-cell>

    <table-cell :label="$t('invoice.original.price')" class="text-end">
      <price-viewer :currency="currency" :value="fOriginalPrice" chip />
    </table-cell>

    <table-cell :label="$t('amount')">
      <price-viewer
        v-if="signed"
        :currency="obItemInvoice.currency"
        :value="sTotalPrice"
      />
      <simple-unit-price-field
        v-else
        :disabled="signed || readonly"
        :rules="priceRules"
        :value="sTotalPrice"
        @price:apply="onAddItem"
        @price:update="onSetPrice"
      />
    </table-cell>

    <table-cell :label="$t('actions')" class="text-lg-end">
      <item-actions
        v-if="canAdd || canDelete"
        :can-add="canAdd"
        :can-delete="canDelete"
        :disabled="signed || !validRow"
        hide-reset
        @item:add="onAddItem"
        @item:delete="onDeleteItem"
      />
    </table-cell>
  </tr>
</template>

<script lang="ts">
import type { InvoiceTypeData } from "@planetadeleste/vue-mc-gw";
import {
  Invoice,
  InvoiceMovementType,
  InvoicePosition,
  InvoiceType,
  InvoiceTypeCollection,
} from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { InvoiceModule } from "@/store/invoice";
import { negative, positive } from "@/plugins/helpers";

import TableCell from "@/components/common/TableCell.vue";
import ItemActions from "@/modules/invoices/components/rows/ItemActions.vue";
import InvoiceSelect from "@/modules/invoices/components/InvoiceSelect.vue";
import PriceViewer from "@/components/common/PriceViewer.vue";
import SimpleUnitPriceField from "@/modules/invoices/components/SimpleUnitPriceField.vue";
import { number } from "mathjs";
import { number as yNumber, object, ValidationError } from "yup";
import IconAdd from "@/components/icons/IconAdd.vue";
import { set } from "lodash";

@Component({
  components: {
    IconAdd,
    TableCell,
    ItemActions,
    InvoiceSelect,
    PriceViewer,
    SimpleUnitPriceField,
  },
})
export default class DebtItemRow extends Vue {
  @VModel({ type: Object, default: () => new InvoicePosition() })
  item!: InvoicePosition;

  obItemInvoice: Invoice = new Invoice();
  obInvoiceTypeCollection: InvoiceTypeCollection = new InvoiceTypeCollection();
  obInvoiceFilters: Record<string, any> = {};
  bFiltersDone = false;
  sError: string | null = null;

  @Prop(Boolean) readonly canAdd!: boolean;
  @Prop(Boolean) readonly canDelete!: boolean;
  @Prop(Boolean) readonly hideIndicator!: boolean;
  @Prop(Boolean) readonly readonly!: boolean;

  get currency() {
    return InvoiceModule.currency;
  }

  get itemInvoiceId(): number {
    return this.item.get("item_id", 0);
  }

  set itemInvoiceId(sValue: number) {
    this.item.set("item_id", sValue);
  }

  get itemInvoiceType(): Partial<InvoiceTypeData> {
    return this.obItemInvoice.get("invoice_type");
  }

  get sCfeName() {
    let sValue = this.obItemInvoice.get("cfe_name") as string | undefined;

    if (!sValue && this.itemInvoiceType) {
      sValue = this.itemInvoiceType.name;
    }

    return sValue;
  }

  /*get bFiltersDone() {
    const obSchema = object({
      invoice_type_id: yNumber().required(),
      customer: yNumber().required(),
      currency: yNumber().required(),
    });

    return obSchema.validateSync(this.obInvoiceFilters);
  }*/

  get fBalanceValue() {
    return this.obItemInvoice
      ? number(this.obItemInvoice.get("balance_pending", 0))
      : 0;
  }

  get sTotalPrice() {
    return this.item.get("price", 0);
  }

  get fOriginalPrice() {
    return this.item.get("original_price", 0);
  }

  get sIndicator() {
    return this.item.get("indicator", 6);
  }

  set sIndicator(sValue: number) {
    const fPrice =
      sValue === 7 &&
      [InvoiceMovementType.CODE_DEBT, 15].includes(this.iMovementType)
        ? negative(this.sTotalPrice)
        : positive(this.sTotalPrice);

    if (fPrice !== this.sTotalPrice) {
      // this.obItemInvoice.total_price_value = fPrice;
      this.item.set("price", fPrice);
    }

    this.item.set("indicator", sValue);
  }

  get invoice() {
    return InvoiceModule.invoice;
  }

  get omit() {
    return this.$_.chain(InvoiceModule.positions)
      .map((obPosition) => obPosition.item_id)
      .filter((sItemId) => sItemId !== this.item.item_id)
      .value();
  }

  get signed() {
    return InvoiceModule.signed || InvoiceModule.isSigning || this.inView;
  }

  get inView() {
    return InvoiceModule.inView;
  }

  get iMovementType(): number {
    return InvoiceModule.iMovementType;
  }

  get validRow() {
    return !!this.item.item_id && !!this.item.price;
  }

  get rowClass() {
    return {
      "invoice-table-row": true,
      "invoice-table-row--edit": this.canAdd,
      "v-data-table__mobile-table-row": this.isMobile,
    };
  }

  get priceRules() {
    if (this.signed) {
      return null;
    }

    const arRules = ["required"];

    if (this.fBalanceValue) {
      if (this.sIndicator === 6 || this.iMovementType === 14) {
        arRules.push("min_value:1", `max_value:${this.fBalanceValue}`);
      } else {
        arRules.push("max_value:-1", `min_value:-${this.fBalanceValue}`);
      }
    }

    return arRules.join("|");
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  @Watch("obInvoiceFilters")
  async onValidateFilters(obFilters: Record<string, any>) {
    const obSchema = object({
      invoice_type_id: yNumber().required(),
      customer: yNumber()
        .required()
        .label(this.$t("customer") as string),
      currency: yNumber()
        .required()
        .label(this.$t("currency") as string),
    });

    try {
      await obSchema.validate(obFilters);
      this.bFiltersDone = true;
      this.sError = null;
      // @ts-ignore
    } catch (err: ValidationError) {
      this.bFiltersDone = false;
      this.sError = err.errors[0];
    }
  }

  async mounted() {
    // Preload invoice types to used un filters
    if (!this.obInvoiceTypeCollection.length) {
      const obTypeFilter = { code: ["101", "102", "111", "112", "901"] };
      await this.obInvoiceTypeCollection.filterBy(obTypeFilter).fetch();
    }

    this.obItemInvoice = new Invoice();
    this.onSetFilters();
    await this.onLoadItemInvoice();
  }

  async onLoadItemInvoice() {
    if (this.itemInvoiceId) {
      this.obItemInvoice.set("id", this.itemInvoiceId);
      await this.obItemInvoice.fetch();
    }
    /*
    if (this.sTotalPrice) {
      this.item.set("original_price", this.sTotalPrice);
    }
    */
  }

  onSetFilters() {
    const arInvoiceType: number[] = [];
    let sCashCredit = this.invoice.is_cash ? "cash" : "credit";

    this.item.set("indicator", 6);
    if (this.iMovementType === 14) {
      // Add settings for type "ANULAR COBRANZA"
      this.item.set("indicator", 7);
      set(
        this.obInvoiceFilters,
        "invoiceMovementTypeCode",
        InvoiceMovementType.CODE_DEBT
      );
      this.obInvoiceTypeCollection.each((obModel) => {
        if (number(obModel.code) === InvoiceType.CODE_ECOBRANZA) {
          arInvoiceType.push(obModel.id);
        }
      });
    } else {
      this.obInvoiceTypeCollection.each((obModel) => {
        if (["101", "111"].includes(obModel.code)) {
          arInvoiceType.push(obModel.id);
        }
      });

      if (
        [
          InvoiceMovementType.CODE_DEBT,
          InvoiceMovementType.CODE_DEBT_RYA,
        ].includes(this.iMovementType)
      ) {
        sCashCredit = "credit";
      }
    }

    set(this.obInvoiceFilters, "invoice_type_id", arInvoiceType);
    set(this.obInvoiceFilters, "signed", 1);
    set(this.obInvoiceFilters, sCashCredit, 1);
    set(this.obInvoiceFilters, "customer", this.invoice.customer_id);
    set(this.obInvoiceFilters, "currency", this.invoice.currency_id);
  }

  onSelectItemInvoice(obModel?: Invoice) {
    if (!obModel) {
      return;
    }

    const fValue =
      this.sIndicator === 7 &&
      [
        InvoiceMovementType.CODE_DEBT,
        InvoiceMovementType.CODE_DEBT_RYA,
      ].includes(this.iMovementType)
        ? negative(obModel.get("total_price_value"))
        : obModel.get("total_price_value");
    obModel.set("total_price_value", fValue);

    this.item.set("item_id", obModel.id);
    this.item.set("quantity", 1);

    if (!this.sTotalPrice) {
      this.onSetPrice(fValue);
    } else {
      this.onUpdateItem();
    }

    this.obItemInvoice = new Invoice(obModel.attributes);
  }

  onSetPrice(fValue: number) {
    this.item.set("price", fValue);
    this.item.set("price_with_discounts", fValue);
    this.onUpdateItem();
    // this.item.set("price_with_discounts", fValue);
  }

  onAddItem() {
    if (this.signed || !this.canAdd) {
      return;
    }

    this.$emit("add:empty");
  }

  onDeleteItem() {
    if (!this.canDelete) {
      return;
    }

    this.$emit("del", this.item);
  }

  onUpdateItem() {
    this.$emit("update", this.item);
  }
}
</script>
